<template>
    <div class="public">
        <NotificationBar v-if="shouldShowNotificationBar"/>
        <Header
            :position="showHeader"
            @removeRibbon="(isOpen) => (showRibbon = !isOpen)"
        />
        <main class="public-main">
            <router-view></router-view>
        </main>
        <Chat :state="showChat" v-if="showChat" />
        <Footer />
        <LoginModal />
        <SignupModal />
        <CustomizeModal />
        <LiveDataModal />
        <ServiceModal />
        <VendorModal />
        <LangModalForMobile />
        <NotificationModal />
        <SaleModal v-if="shouldShowCampaignPopUp" />
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Chat from '../components/Chat';
import LoginModal from '@/components/modals/LoginModal';
import SignupModal from '@/components/modals/SignupModal';
import CustomizeModal from '@/components/modals/CustomizeModal';
import LiveDataModal from '@/components/modals/LiveDataModal';
import ServiceModal from '@/components/modals/ServiceModal';
import VendorModal from '@/components/modals/VendorModal';
import LangModalForMobile from '@/components/modals/LangModalForMobile';
import NotificationModal from '@/components/modals/NotificationModal';
import SaleModal from '@/components/modals/SaleModal';
import { mobileAndTabletCheck, openModal } from '@/utils/helpers';
import appConfig from '@/config/app.config';
import NotificationBar from '@/components/NotificationBar.vue';
import debounce from "lodash.debounce";

const route = useRoute();
const store = useStore();
const showHeader = ref(false);
const showChat = ref(true);
const showRibbon = ref(true);

const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');
const isInUS = computed(() => store.state.prices?.prices?.countryCode === 'US');

const shouldShowCampaignPopUp = computed(() => store.state.campaign?.popUp?.title && appConfig.ENABLE_CAMPAIGNS);
const shouldShowNotificationBar = computed(() => {
    return (store.state.campaign?.bar?.text || store.state.campaign?.bar?.tag) && appConfig.ENABLE_CAMPAIGNS && showRibbon.value;
});

const whiteBlocks = () => {
    if (localStorage.showHeader) showHeader.value = true;
    window.setInterval(() => {
        showHeader.value = true;
        localStorage.setItem('showHeader', true);
    }, 3500);
};

onBeforeMount(() => {
    if (route.name === 'home') {
        showHeader.value = true;
    } else {
        whiteBlocks();
    }

    if (
        !localStorage.getItem('previouslyVisited')
    ) {
      localStorage.setItem('previouslyVisited', 'true');
      // Disabled becuase of bad user experience with hard to see close button
      openModal('Sale', true);
    }
});

const handleWindowSizeChange = () => {
    store.dispatch('device/isMobile', mobileAndTabletCheck());
};

const debouncedHandleWindowSizeChange = debounce(handleWindowSizeChange, 200);

onMounted(() => {
    document.body.classList.remove('overflow-hidden');
    debouncedHandleWindowSizeChange();
    window.addEventListener('resize', debouncedHandleWindowSizeChange);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', debouncedHandleWindowSizeChange);
});
</script>
