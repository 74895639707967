<template>
  <div
    v-if="pricing && prices && prices.appPricePerMonth"
    class="app-and-adapter"
  >
    <div class="container">
      <h1 v-if="isPricingPage(route.name)" class="main-title">
        {{ pricing?.headings?.h1[0] }}
      </h1>
      <h2 v-else class="main-title">
        {{ pricing?.headings?.h2[1] }}
      </h2>
      <h3 class="main-subtitle">
        {{ mainSubtitle }}
      </h3>
    </div>
    <div class="container app-and-adapter-content-wrapper">
      <div class="content">
        <div class="cards-container">
          <CardAdapterNew
            v-if="!disabled && !isMobile"
            type="obd"
            :imgSrc="pricingData?.images[0]?.url"
            :imgAlt="pricingData?.images[0]?.imageMeta?.metaAltDescription"
            :imgTitle="pricingData?.images[0]?.imageMeta?.metaTitle"
            :title="pricing?.headings?.h2[2]"
            :price="prices?.adapterPrice"
            :campaignPrice="campaignPriceObd"
            :tag="campaignTagObd"
            :tagUrl="tagObdUrl"
            :bulletpoints="pricingData?.items[1].content.content"
          />
          <CardAdapterNew
            type="evo"
            :imgSrc="pricingData?.images[1]?.url"
            :imgAlt="pricingData?.images[1]?.imageMeta?.metaAltDescription"
            :imgTitle="pricingData?.images[1]?.imageMeta?.metaTitle"
            :title="pricing?.headings?.h2[3]"
            :price="prices?.evoAdapterPrice"
            :campaignPrice="campaignPriceEvo"
            :tag="campaignTagEvo"
            :tagUrl="tagEvoUrl"
            :bulletpoints="pricingData?.items[0].content.content"
          />
          <!-- Copy of CardAdapter OBD because on mobile the scroll doesn't work with row-reverse -->
          <CardAdapterNew
            v-if="!disabled && isMobile"
            type="obd"
            :imgSrc="pricingData?.images[0]?.url"
            :imgAlt="pricingData?.images[0]?.imageMeta?.metaAltDescription"
            :imgTitle="pricingData?.images[0]?.imageMeta?.metaTitle"
            :title="pricing?.headings?.h2[2]"
            :price="prices?.adapterPrice"
            :campaignPrice="campaignPriceObd"
            :tag="campaignTagObd"
            :tagUrl="tagObdUrl"
            :bulletpoints="pricingData?.items[1].content.content"
          />
        </div>
        <div class="adapter-icons">
          <div class="icon"><ShieldTickIcon /><div>{{ t('shared_two-year-warranty') }}</div></div>
          <div class="icon"><PiggyBankIcon /><div>{{ t('shared_30-day-money-back') }}</div></div>
          <div class="icon"><LockIcon /><div>{{ t('shared_secure-payment') }}</div></div>
          <div v-if="shouldShowFreeShippingIcon" class="icon"><ShippingFastTruckBoldIcon color="#1B1E21"/><div>{{ t('shared_free-shipping') }}</div></div>
        </div>
      </div>
    </div>
    <div class="vehicle-selector-switch-wrapper">
      <div class="vehicle-selector-switch-container container" v-if="$route.name !== 'app'">
        <div class="title">{{ t('page-pricing_discover-what-carista-can-achieve') }}</div>
        <ActionButton
        v-if="!(isMobile && showVehicleSelector)"
        :showText="isMobile"
        :customText="t('shared_available-features')"
        :showArrow="true"
        :isArrowRotated="showVehicleSelector"
        :width="isMobile ? '318px' : 'auto'"
        @click="toggleVehicleSelector"
        />
      </div>
    </div>
    <div class="vehicle-selector-container" v-if="showVehicleSelector">
      <div class="header-container">
        <div v-if="dynamicVehiclesData?.param === 'make'">
          {{ t('page-supported-cars_choose-your-car-brand') }}
        </div>
        <div v-if="dynamicVehiclesData?.param === 'model'">
          {{ t('page-supported-cars_choose-your-car-model') }}
        </div>
        <div v-if="dynamicVehiclesData?.param === 'generation'">
          {{ t('page-supported-cars_choose-your-car-generation') }}
        </div>
        <button
          class="reset-btn"
          v-if="
            dynamicVehiclesData?.param === 'model' || dynamicVehiclesData?.param === 'generation'
          "
          @click="reset"
        >
          <ResetIcon /> {{ t("page-supported-cars_reset") }}
        </button>
      </div>
      <div v-if="!dynamicVehiclesData?.selector?.generation" class="search-box-container">
        <VehicleSelectorSearchBox
          :loading="loading"
        />
      </div>
      <WizardProgressBar :showBtns="false" v-if="!dynamicVehiclesData?.selector?.generation" />
      <VehicleFeatureSection v-if="dynamicVehiclesData?.selector?.generation" />
      <div v-if="isMobile" class="close_button_container">
        <ActionButton
        :showText="isMobile"
        :customText="t('shared_close')"
        :showArrow="true"
        :isArrowRotated="showVehicleSelector"
        :style="{ 'margin-top': '30px' }"
        @click="toggleVehicleSelector"
        />
      </div>
    </div>
    <div class="app-pricing-container" v-if="$route.name !== 'app'">
      <AppPricing class="container" />
      <FeatureHighlights />
      <UserRatings />
    </div>
    <div class="comparison-table-wrapper" v-if="isPricingPage(route.name) && !disabled">
      <ComparisonTable class="container"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from "i18next-vue";
import { isPricingPage } from "@/utils/helpers";
import CardAdapterNew from "../pricing/CardAdapterNew.vue";
import AppPricing from './AppPricing.vue';
import ComparisonTable from "@/components/pricing/ComparisonTable";
import WizardProgressBar from "@/components/supported-cars/WizardProgressBar";
import VehicleSelectorSearchBox from "@/components/supported-cars/VehicleSelectorSearchBox";
import VehicleFeatureSection from '../pricing/VehicleFeatureSection.vue';
import ActionButton from '../UI-elements/ActionButton.vue';
import ResetIcon from "@/assets/icons/ResetIcon";
import ShieldTickIcon from "@/assets/icons/ShieldTickIcon";
import LockIcon from "@/assets/icons/LockIcon";
import PiggyBankIcon from '@/assets/icons/PiggyBankIcon.vue';
import FeatureHighlights from '../FeatureHighlights.vue';
import UserRatings from '../UserRatings.vue';
import { APP_PAGE_ROUTE } from '@/utils/constants';
import ShippingFastTruckBoldIcon from '@/assets/icons/ShippingFastTruckBoldIcon.vue';

const store = useStore();
const route = useRoute();
const { t } = useTranslation(['shared', 'page-supported-cars', 'page-pricing']);

const loading = ref(false);

const langCode = computed(() => store.state.language.lang?.code);
const prices = computed(() => store.state.prices?.prices);
const pricing = computed(() => store.state.pages?.pricing);
const dynamicVehiclesData = computed(() => store.state.vehiclebrands.dynamicVehiclesData);
const showVehicleSelector = ref((!!dynamicVehiclesData.value.selector?.generation) && route.name !== APP_PAGE_ROUTE);

const campaignTagEvo = computed(() => store.state.campaign?.tags?.appAndPricingEvo);
const campaignTagObd = computed(() => store.state.campaign?.tags?.appAndPricingObd);
const tagEvoUrl = computed(() => store.state.campaign?.tags?.appAndPricingEvoUrl);
const tagObdUrl = computed(() => store.state.campaign?.tags?.appAndPricingObdUrl);
const campaignPriceEvo = computed(() => store.state.campaign?.prices?.evo);
const campaignPriceObd = computed(() => store.state.campaign?.prices?.obd);

const shouldShowFreeShippingIcon = computed(() => {
  if (route.name !== 'app') {
    return false;
  }

  const countryCode = store.state.prices?.prices?.countryCode;

  return countryCode === 'GB' || countryCode === 'US';
})

const mainSubtitle = computed(() => {
  if (isPricingPage(route.name)) {
    return pricing.value?.headings?.h3[4];
  }

  return pricing.value?.headings?.h3[5];
})

const pricingData = computed (() => {
  const data = pricing.value?.children;

  if (!Array.isArray(data)) {
      return;
  }

  if (route.name === 'pricing-evo') {
    return data.find(el => el.tag === 'pricing_evo');
  }

  return data.find(el => el.tag === 'pricing');
});

const disabled = computed(() => route.name === 'pricing-evo');

const isMobile = computed(() => store.state.device.isMobile);

onMounted(async () => {
  if (!dynamicVehiclesData.value.selector) {
    loading.value = true;
    await store.dispatch("vehiclebrands/getDynamicVehiclesData", {});
    loading.value = false;
    await store.dispatch("vehiclebrands/getSummary");
  }
});

function toggleVehicleSelector() {
  showVehicleSelector.value = !showVehicleSelector.value;
}

const reset = () => {
  store.dispatch("vehiclebrands/getDynamicVehiclesData", {});
};
</script>

<style lang="scss">
.close_button_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
