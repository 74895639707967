<template>
  <div v-if="app" class="product-app">
    <BreadCrumb class="text-white" />
    <div class="hero page">
      <div class="container">
        <h1 class="main-title">{{ app?.headings.h1[0] }}</h1>
        <h3 class="main-subtitle">
          {{ app?.headings.h3[0] }}
        </h3>
        <div class="hero-content">
          <div class="img-wrapper">
            <img
              :src="app?.children[0].images[0].url"
              :alt="app?.children[0].images[0].imageMeta.metaAltDescription"
              :title="app?.children[0].images[0].imageMeta.metaTitle"
              loading="lazy"
            />
          </div>
          <div class="text-wrapper">
            <p>{{ t("page-app_carista-app-description-1") }}</p>
            <p>{{ t("page-app_carista-app-description-2") }}</p>
            <p class="store-title">{{ t("page-app_available-on") }}</p>
            <div
              class="store-container"
            >
              <a
                :href="$appConfig.appleAppUrl"
                target="_blank"
                class="btn-store-outlined"
                @click="facebookGoogleAnalitycs('app_store_click', {
                  button_position: 'app page',
                  platform: 'App Store'
                })"
              >
                <AppStoreIcon />
              </a>
              <a
                :href="$appConfig.googleAppUrl"
                target="_blank"
                class="btn-store-outlined"
                @click="facebookGoogleAnalitycs('app_store_click', {
                  button_position: 'app page',
                  platform: 'Google Play'
                })"
              >
                <GoogePlayIcon />
              </a>
            </div>
            <!-- Do NOT delete. This will be added back later  -->
            <!-- <p class="store-title hide-on-mobile">
              {{ $t("page-app_get-the-app") }}
            </p>
            <div class="hide-on-mobile get-app">
              <p>
                {{
                  $t(
                    "page-app_open-carista-app-on-your-phone-by-scanning-the-qr-code"
                  )
                }}
              </p>
              <img
                :src="app.children[0].image2Url"
                :alt="app.children[0]?.image2UrlAlt"
                loading="lazy"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <HomePageTools :active="2" />
    <div class="why-app">
      <div class="container">
        <h2>{{ app?.headings.h2[0] }}</h2>
        <div class="content">
          <div class="img-wrapper hide-on-mobile">
            <img
              :src="app?.children[0].images[0].url"
              :alt="app?.children[0]?.images[0].imageMeta.metaAltDescription"
              :title="app?.children[0]?.images[0].imageMeta.metaTitle"
              loading="lazy"
            />
            <!-- <video class="video" autoplay loop :src="app.children[1].videoUrl" /> -->
          </div>
          <div class="text">
            <p>
              {{ t("page-app_experience-our-interface-gradient-1") }}
              <span
                class="text-gradient"
                :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
                >{{ t("page-app_experience-our-interface-gradient-2") }}</span
              >
              <br class="hide-on-mobile" />
              {{ t("page-app_experience-our-interface-gradient-3") }}
            </p>
            <p>
              {{ t("page-app_save-more-than-700-gradient-1") }}
              <span
                class="text-gradient"
                :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
                >{{ t("page-app_save-more-than-700-gradient-2") }}</span
              >
              {{ t("page-app_save-more-than-700-gradient-3") }}
              <br class="hide-on-mobile" />
              {{ t("page-app_save-more-than-700-gradient-4") }}
            </p>
            <p>
              {{
                t(
                  "page-app_resolve-inquiries-with-the-help-of-our-team-gradient-1"
                )
              }}
              <br class="hide-on-mobile" />
              {{
                t(
                  "page-app_resolve-inquiries-with-the-help-of-our-team-gradient-2"
                )
              }}
              <span
                class="text-gradient"
                :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
              >
                {{
                  t(
                    "page-app_resolve-inquiries-with-the-help-of-our-team-gradient-3"
                  )
                }}
                {{
                  t(
                    "page-app_resolve-inquiries-with-the-help-of-our-team-gradient-4"
                  )
                }}
              </span>
            </p>
            <p v-html="whyAppSubText" :style="{width: 400}"></p>
          </div>
        </div>
      </div>
    </div>
    <SupportedBrands />
    <HowItWorks
      id="appPageHowItWorks"
      :active="active"
      :activeStep="activeStep"
      @changeActiveStep="changeActiveStep"
    />
    <MobileAppAndAdapter id="mobileAppAndAdapter" />
    <VueperSlider
      :title="app?.headings.h4[0]"
      :testimonials="appTestimonials"
      :active="6"
      :static="1"
      ref="testimonials"
    />
    <Faq :active="7" :static="true" :faqList="appFaq" :title="app?.headings.h4[1]"/>
    <PromoSection
    :shouldOpenVendorModal="true"
      fbEventParam="app page"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import BreadCrumb from "@/components/breadcrumb/BreadCrumb";
import HowItWorks from "@/components/HowItWorks";
import SupportedBrands from "@/components/adapter/SupportedBrands";
import MobileAppAndAdapter from "@/components/adapter/MobileAppAndAdapter";
import HomePageTools from "@/components/homepage/HomePageTools";
import AppStoreIcon from "@/assets/icons/AppStoreIcon";
import GoogePlayIcon from "@/assets/icons/GoogePlayIcon";
import VueperSlider from "@/components/VueperSlider";
import Faq from "@/components/Faq";
import {
  detectSamsungBrowser,
  facebookGoogleAnalitycs,
  createMetaInfo,
  mapLangFullNameToCode,
  formatPhraseWithHtml
} from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';
import { TESTIMONIALS_CATEGORY_ID, FAQ_CATEGORY_ID } from '@/utils/constants';
import PromoSection from '@/components/PromoSection.vue';

const store = useStore();
const loading = computed(() => store.state.loading);
const appFaqCategory = FAQ_CATEGORY_ID.APP_PAGE;
const appFaq = computed(() => store.state.faq?.faq[appFaqCategory - 1]);
const appTestimonialsCategory = TESTIMONIALS_CATEGORY_ID.APP_PAGE;
const appTestimonials = computed(
  () => store.state.testimonials?.testimonials[appTestimonialsCategory - 1]
);
const app = computed(() => store.state.pages?.app);
const langCode = computed(() => store.state.language.lang?.code);
const { t } = useTranslation(['page-app', 'shared']);

const active = ref(0);
const activeStep = ref(1);
const sectionHowItWorksOffset = ref(null);
const sectionMobileAppAndAdapterOffset = ref(null);
const reducedContant = ref(400);
const testimonials = ref(null);
const isMobile = computed(() => store.state.device.isMobile);
const isSamsungBrowser = detectSamsungBrowser();

const whyAppSubText = computed(() => {
  const text = t('page-app_perform-coding-diagnostics-and-service-quicly-and-reliably');
  const gradient = t('page-app_perform-coding-diagnostics-and-service-quicly-and-reliably-gradient');

  const className = isSamsungBrowser ? 'samsung-bg-gradient' : 'text-gradient';

  return formatPhraseWithHtml(
        text,
        gradient,
        'span',
        className
    );
})

const changeActiveStep = ($event) => {
  activeStep.value = $event;
};

const calcOffsets = () => {
  if (!loading.value) {
    setTimeout(() => {
      // Select and get all sections offsets for visual animations
      const sectionHowItWorks =
        document.querySelector("#appPageHowItWorks");
      const mobileAppAndAdapter = document.querySelector(
        "#mobileAppAndAdapter"
      );

      sectionHowItWorksOffset.value =
        sectionHowItWorks.getBoundingClientRect().top;
      sectionMobileAppAndAdapterOffset.value =
        mobileAppAndAdapter.getBoundingClientRect().top;

      // decrease the value for mobile devices to switch active state 200 pixels before section's offset top
      if (isMobile.value) reducedContant.value = 200;

      window.addEventListener("scroll", handleScroll);
      // window.addEventListener("resize", this.resizeBrowserHandler);
    }, 1000);
  }
};

const calcActiveStep = () => {
  const stepHeight =
    (sectionMobileAppAndAdapterOffset.value - sectionHowItWorksOffset.value) /
    3;
  if (
    window.top.scrollY <
    sectionHowItWorksOffset.value + stepHeight - 400
  ) {
    activeStep.value = 1;
  } else if (
    window.top.scrollY > sectionHowItWorksOffset.value + stepHeight - 400 &&
    window.top.scrollY < sectionHowItWorksOffset.value + stepHeight * 2 - 700
  ) {
    activeStep.value = 2;
  } else if (
    window.top.scrollY >
    sectionHowItWorksOffset.value + stepHeight * 2 - 700
  ) {
    activeStep.value = 3;
  }
};

const handleScroll = () => {
  if (
    window.top.scrollY >
      sectionHowItWorksOffset.value - reducedContant.value &&
    window.top.scrollY <
      sectionMobileAppAndAdapterOffset.value - reducedContant.value
  ) {
    active.value = 5;
    calcActiveStep();
  } else if (
    window.top.scrollY >
    sectionMobileAppAndAdapterOffset.value - reducedContant.value
  ) {
    active.value = 6;
  }
};

onBeforeMount(async () => {
  if (
    !appTestimonials.value ||
    (appTestimonials.value &&
      mapLangFullNameToCode(appTestimonials?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch(
      "testimonials/getTestimonials", { lang: langCode.value, categoryId: appTestimonialsCategory }
    );
  }

  if (
    !appFaq.value ||
    (appFaq.value && mapLangFullNameToCode(appFaq?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch("faq/getFaq", { lang: langCode.value, categoryId: appFaqCategory });
  }
});

onMounted(() => {
  document.body.classList.remove("overflow-hidden");
  calcOffsets();
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

watch(loading, (newVal) => {
  if (!newVal) {
    calcOffsets();
  }
});
watch(langCode, (newVal) => {
  store.dispatch(
    "testimonials/getTestimonials", { lang: newVal, categoryId: appTestimonialsCategory }
  );
  store.dispatch("faq/getFaq", { lang: newVal, categoryId: appFaqCategory });
});

useHead(() => {
  return createMetaInfo({
    title: app.value?.metaTitle,
    pageMeta: app.value,
  });
});
</script>
