<template>
    <div
        :class="{
            'has-selection': value,
            'is-active': activate,
            'is-disabled': disabled,
        }"
        class="accordion-item"
    >
        <div @click="$emit('toggle')" class="main-content">
            <h3 v-if="!(isMobile && value) || activate">{{ label }}</h3>
            <div
                v-if="
                    (!isMobile && selectedItem) ||
                    (isMobile && selectedItem && !activate)
                "
                class="selected-item-wrapper"
            >
                <div v-if="showIcons" class="brand-icon">
                    <component
                        :is="defineAsyncComponent(BRAND_LOGOS[selectedItem.id])"
                    />
                </div>
                <h4>{{ selectedItem?.title }}</h4>
            </div>
            <Transition name="spinner-fade">
                <div
                    v-if="activate && !options.length"
                    class="loading-spinner-wrapper"
                >
                    <LoadingIcon />
                </div>
            </Transition>
        </div>
        <Transition name="accordion-list">
            <ul v-if="activate" :value="value" class="list">
                <li
                    v-for="option in options"
                    @click="$emit('select', option.id)"
                    :key="option.id"
                    :value="option.id"
                    :class="{ 'brand-item': showIcons }"
                >
                    <component
                        v-if="showIcons"
                        :is="defineAsyncComponent(BRAND_LOGOS[option.id])"
                    />
                    <h5>{{ option.title }}</h5>
                    <div v-if="option.startYear" class="generation-container">
                        <span class="generation-text">{{
                            t('page-supported-cars_year')
                        }}</span>
                        <span class="generation-year">{{
                            option.endYear
                                ? `${option.startYear} - ${option.endYear}`
                                : `${option.startYear}+`
                        }}</span>
                    </div>
                </li>
            </ul>
        </Transition>
    </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { BRAND_LOGOS } from '@/utils/constants';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import LoadingIcon from '@/assets/icons/LoadingIcon.vue';

const props = defineProps({
    value: String,
    label: String,
    options: Array,
    showIcons: Boolean,
    activate: Boolean,
    disabled: Boolean,
});

const store = useStore();
const { t } = useTranslation(['page-supported-cars']);

const isMobile = computed(() => store.state.device.isMobile);

const selectedItem = computed(() => {
    return props.options?.find((el) => el.id == props.value);
});
</script>

<style lang="scss" scoped>
.brand-icon {
    height: 50px;

    @media (max-width: $screen-md) {
        height: 42px;
        svg {
            height: 42px;
        }
    }
}

.accordion-item {
    padding: 18px 30px;
    border-radius: var(--Spacing-Border-radius-borderRadius-18, 18px);
    border: 2px solid var(--color-light, #eaecee);

    @media (max-width: $screen-md) {
        padding: 10px 0px 10px 18px;
    }

    .main-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 50px;

        &:hover {
            cursor: pointer;
        }
    }

    .selected-item-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        @media (max-width: $screen-md) {
            &:has(.brand-icon) {
                position: relative;
                left: -8px;
            }
        }
    }
}

h3 {
    font-size: 20px;
    font-weight: 500;
    color: $color-neutral-deep;
    letter-spacing: -0.36px;

    @media (max-width: $screen-md) {
        font-size: 20px;
    }
}

h4 {
    font-size: 18px;
    font-weight: 500;
}

.has-selection:not(.is-active) {
    h3,
    h4 {
        color: $color-neutral-darker;
    }
}

.is-active {
    background-color: $color-neutral-lightest;
    h3 {
        font-size: 24px;

        @media (max-width: $screen-md) {
            font-size: 20px;
        }
    }
}

.is-disabled {
    background-color: $color-light;
    opacity: 0.4;
    pointer-events: none;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 248px;
    overflow-y: auto;

    margin-top: 30px;
    padding-top: 12px;

    @media (max-width: $screen-md) {
        margin-top: 18px;
        padding-top: 0px;
    }

    &:has(.brand-item) {
        gap: 2px;
    }

    li {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &:not(.brand-item):hover {
            h5 {
                color: $color-primary-default;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &.brand-item {
            padding: 18px 6px 18px 0px;
            border-radius: 18px;
            gap: 6px;
            flex-direction: row;
            align-items: center;

            @media (max-width: $screen-md) {
                padding: 16px 6px 12px 0px;
                position: relative;
                left: -12px;
            }

            svg {
                height: 36px;

                @media (max-width: $screen-md) {
                    height: 34px;
                }
            }
        }

        &.brand-item:hover {
            background-color: white;
            cursor: pointer;

            @media (max-width: $screen-md) {
                background-color: inherit;
            }
        }
    }

    h5 {
        font-size: 20px;
        font-weight: 500;
        color: $color-neutral-darkest;

        @media (max-width: $screen-md) {
            font-size: 18px;
        }
    }

    div {
        margin-bottom: 10px;
    }
}

.generation-container {
    display: flex;
    gap: 16px;

    font-size: 16px;
    font-weight: 500;

    .generation-text {
        color: $color-gray;
    }

    .generation-year {
        color: $color-neutral-deep;
    }
}

.is-active:not(.is-disabled) {
    .loading-spinner-wrapper {
        opacity: 0.4;
    }
}

.loading-spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 20px;

        @media (max-width: $screen-md) {
            width: 35px;
            height: 35px;
        }
    }
}

.accordion-list-enter-active,
.accordion-list-leave-active {
    transition: all 400ms ease-in-out;
}

.accordion-list-enter-from,
.accordion-list-leave-to {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    //   transform: translateY(-10px);
}

.spinner-fade-enter-active {
    transition: all 400ms ease-in-out;
}

.spinner-fade-leave-active {
    transition: all 100ms ease-in-out;
}

.spinner-fade-enter-from,
.spinner-fade-leave-to {
    opacity: 0;
    height: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: transparent;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: $color-neutral-light;
    border-radius: 4px;
}
</style>
