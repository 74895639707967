<template>
    <div class="embark">
        <div class="container">
            <div class="content">
                <h4
                    v-if="titleFormatted"
                    v-html="titleFormatted"
                    class="title"
                ></h4>
                <GetStarted
                    :shouldOpenVendorModal
                    :to="to ? to : `/${langCode}/pricing-evo`"
                    :title="defaultButtonTitle"
                    :subtitle="defaultButtonSubTitle"
                    :fbEventParam="fbEventParam"
                    :fbEvent="fbEvent"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import GetStarted from '../components/GetStarted.vue';
import { detectSamsungBrowser, formatPhraseWithHtml } from '@/utils/helpers';

const { t } = useTranslation(['shared', 'page-supported-cars']);

const props = defineProps({
    title: {
        type: String,
    },
    buttonTitle: {
        type: String,
    },
    buttonSubTitle: {
        type: String,
    },
    shouldOpenVendorModal: {
        type: Boolean,
        default: false,
    },
    to: [String, Object],
    fbEvent: String,
    fbEventParam: String,
});

const store = useStore();

const langCode = computed(() => store.state.language.lang?.code);
const isMobile = computed(() => store.state.device.isMobile);

const gradientSpanStyle = computed(() => {
    if (!props.title || !props.title.length) {
        return {};
    }

    const shouldDisplayInline =
        props.title[1]?.toLowerCase() === 'free' ||
        props.title[1]?.toLowerCase() === 'evolve';

    return { display: shouldDisplayInline ? 'inline' : 'block' };
});

const titleFormatted = computed(() => {
    if (props.title) {
        return props.title;
    }

    const gradientPhraseClasses = isSamsungBrowser.value
        ? 'text-gradient samsung-bg-gradient'
        : 'text-gradient';

    const firstLine = formatPhraseWithHtml(
        t('shared_carista-adjusts-the-gear'),
        t('shared_carista-gradient'),
        'span',
        gradientPhraseClasses
    );
    const secondLine = formatPhraseWithHtml(
        t('shared_you-own-the-drive'),
        t('shared_you-own-the-drive-gradient'),
        'span',
        gradientPhraseClasses
    );

    return firstLine + (isMobile.value ? '' : '<br>') + secondLine;
});

const defaultButtonTitle = computed(
    () => props.buttonTitle || t('shared_get-now')
);
const defaultButtonSubTitle = computed(
    () => props.buttonSubTitle || t('shared_your-app-and-scanner')
);

const isSamsungBrowser = ref(detectSamsungBrowser());
</script>
