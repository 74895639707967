<template>
  <section class="container">
    <div class="homepage__section homepage__adapter">
      <div class="content" :class="{ active: isActive }">
        <div class="left">
          <h2 v-html="title">
          </h2>
        </div>

        <div class="placeholder">
          <img
            src="/images/homePage/carista-evo-obd2-scanner.webp"
            :alt="home?.children[1]?.images[1]?.imageMeta?.metaAltDescription"
            :title="home?.children[1]?.images[1]?.imageMeta?.metaTitle"
            loading="lazy"
          />
        </div>

        <div class="right">
          <p
            class="plain-text"
            v-html="t('page-home_about-adapter-content')"
          ></p>
          <router-link
            :to="`/${langCode}/adapters`"
            class="btn-feedback-outlined lighter"
            >{{ t("page-home_more-about-the-adapter") }} <ArrowRightIcon />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ArrowRightIcon from '../../assets/icons/supportedCars/ArrowRightIcon.vue';
import { detectSamsungBrowser, formatPhraseWithHtml } from "@/utils/helpers";

const { t } = useTranslation(['page-home']);

const props = defineProps({
  active: Number,
});

const isSamsungBrowser = ref(detectSamsungBrowser());

const store = useStore();

const isActive = computed(() => props.active === 4 || props.active === 3);
const home = computed(() => store.state.pages?.homepage);
const langCode = computed(() => store.state.language.lang?.code);

const title = computed(() => {
  return formatPhraseWithHtml(
        t('page-home_about-scanner-title'),
        t('page-home_about-scanner-title-gradient'),
        'span',
        'text-gradient'
    );
})
</script>
