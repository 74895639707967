<template>
    <div id="supported-cars-wrapper" class="supported-cars-wrapper">
        <BreadCrumb class="hide-on-mobile" />
        <SupportedCarsResultPage
      v-if="
        $router.currentRoute.value.params.make &&
        $router.currentRoute.value.params.model &&
        $router.currentRoute.value.params.generation
      "
    />
        <div v-else class="container supported-cars-content">
            <h1>{{ t('page-supported-cars_check-your-car-compatibility') }}</h1>
            <VehicleSelectorTool />
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ResetIcon from '@/assets/icons/ResetIcon';
import BreadCrumb from '@/components/breadcrumb/BreadCrumb';
import SupportedCarsHeaders from '@/components/supported-cars/SupportedCarsHeaders';
import SupportedCarsSearchBox from '@/components/supported-cars/SupportedCarsSearchBox';
import SupportedCarsResultPage from "./SupportedCarsResultPage.vue";
import VehicleSelectorTool from '@/components/supported-cars/VehicleSelectorTool.vue';
import {
    createMetaInfo,
    addParamsToStringFromCMS,
    pathToPricing,
} from '@/utils/helpers';
import { useTranslation } from 'i18next-vue';
import { useHead } from '@unhead/vue';

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['page-supported-cars']);
const loading = ref(false);

const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const langCode = computed(() => store.state.language.lang?.code);
const supported_cars = computed(() => store.state.pages.supported_cars);

const reset = () => {
    router.push({
        name: 'supported-cars',
        query: router.currentRoute.value.query,
    });
};

onMounted(async () => {
    document.body.classList.remove('overflow-hidden');
    await store.dispatch('vehiclebrands/getSummary');
});

useHead(() => {
    let canonical = '';
    let vehicleName = '';
    Object.values(selector.value).forEach((tag) => {
        canonical += `/${tag.id}`;
        vehicleName += ` ${tag.title}`;
    });

    const metaTitle = addParamsToStringFromCMS(
        supported_cars.value?.metaTitle,
        [vehicleName]
    );
    const metaDescription = addParamsToStringFromCMS(
        supported_cars.value?.metaDescription,
        [vehicleName]
    );

    return createMetaInfo({
        title: metaTitle,
        pageMeta: {
            ...supported_cars.value,
            metaDescription: metaDescription,
            canonicalHref: `${supported_cars.value?.canonicalHref}${canonical}`,
        },
    });
});
</script>

<style lang="scss" scoped>

h1 {
    color: $color-neutral-deep;
    text-align: center;
    margin-bottom: 70px;

    /* Headline/H1 */
    font-size: 72px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.08px;

    @media (max-width: $screen-md) {
        /* Mobile/H1 */
        font-size: 44px;
        letter-spacing: -1.1px;
        margin: 0px 12px 42px 12px;
    }
}

.supported-cars-content {
    @media (max-width: $screen-md) {
        width: 100%;
        padding-top: 42px;
        background-color: $color-neutral-pale;
    }
}
</style>
